import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable, DataTableRowClickEvent } from 'primereact/datatable';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import './message-center.scss';
import { DateTime } from 'luxon';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';
import { MenuItem } from 'primereact/menuitem';
import * as QueryString from 'query-string';
import { Loader } from 'concert-ui-library';
import { useSelector } from 'react-redux';
import { Conversation, useGetConversationsQuery } from '../../services/graphql/generated';
import { NotificationContext } from '../../notification-context';
import { ROUTES, ROUTE_PARAMS } from '../../constants';
import { ConversationContext } from './message-sender';
import {
    EPISODE_INACTIVE_WARNING_MESSAGE,
    PATIENT_CANNOT_BE_MESSAGED_ERROR_MESSAGE,
    SEND_SECURE_MESSAGES_CUSTOM_PERMISSION,
} from './constants';
import { selectUser } from '../user/selector';
import { User } from '../user/slice';

export const MessageCenter: FunctionComponent = () => {
    const currentLocation = useLocation();
    const history = useHistory();
    const notificationContext = useContext(NotificationContext);
    const user: User | null = useSelector(selectUser);
    const { episodeId } = QueryString.parse(currentLocation.search);
    const { state } = currentLocation;
    const {
        data: conversation,
        error: conversationError,
        isFetching,
        refetch: refetchConversations,
    } = useGetConversationsQuery({
        request: {
            episodeId: episodeId as string,
            userId: '',
        },
    });
    const rowClassName = (rowData: Conversation) => {
        return rowData.hasUnopenedMessages ? 'unopened-messages-conversation' : '';
    };
    const dateTemplate = (rowData: string) => {
        const fileDateTime = DateTime.fromISO(rowData, { zone: 'utc' });
        return fileDateTime.toFormat('LL/dd/yyyy: HH:mm');
    };
    const handleRowClick = (s: DataTableRowClickEvent) => {
        const rowData = s.data as Conversation;
        const conversationContext: ConversationContext = {
            episodeId: rowData.episodeId,
            clinicianId: rowData.clinicianId,
        };
        history.push({
            pathname: ROUTES.MESSAGE_THREAD,
            search: `?${ROUTE_PARAMS.EPISODE_ID}=${episodeId}&${ROUTE_PARAMS.CONVERSATION_ID}=${rowData.id}`,
            state: { conversationContext },
        });
    };
    const canSendMessage = () =>
        conversation?.getConversations?.patientCommunicationInformation?.canReceiveCommunications === true &&
        user?.sfCustomPermissions?.includes(SEND_SECURE_MESSAGES_CUSTOM_PERMISSION);

    const getPatientName = () =>
        conversation?.getConversations?.conversations.length
            ? conversation?.getConversations.conversations[0].patientName
            : null;

    const placeholderErrorMessage = 'Something Went wrong while fetching the conversations.';
    const requestedDataInvalidation = () =>
        state !== null && typeof state === 'object' && 'refresh' in state && state.refresh === true;

    const getNavigationModel = (): MenuItem[] => {
        return [
            {
                id: 'patient',
                label: getPatientName() ?? '',
            },
        ];
    };
    const getHomeItem = (): MenuItem => ({
        id: 'home',
        label: 'Messaging',
    });

    useEffect(() => {
        if (conversationError) {
            notificationContext?.showError(placeholderErrorMessage);
        }
    }, [conversationError]);
    useEffect(() => {
        if (requestedDataInvalidation()) {
            refetchConversations();
        }
    }, []);
    useEffect(() => {
        const communicationInfo = conversation?.getConversations?.patientCommunicationInformation;
        if (communicationInfo && communicationInfo.canReceiveCommunications === false) {
            notificationContext?.showWarning(
                communicationInfo.hasActiveEpisode
                    ? PATIENT_CANNOT_BE_MESSAGED_ERROR_MESSAGE
                    : EPISODE_INACTIVE_WARNING_MESSAGE,
            );
        }
    }, [conversation]);

    return (
        <div className="app-container message-center-container">
            {isFetching ? (
                <Loader />
            ) : (
                <>
                    <BreadCrumb model={getNavigationModel()} home={getHomeItem()} />
                    <div className="new-conversation-btn">
                        <Button
                            icon="pi pi-plus"
                            className="p-button p-component p-button-primary"
                            data-testid="new-row"
                            disabled={!canSendMessage()}
                            onClick={() =>
                                history.push({
                                    pathname: ROUTES.MESSAGE_THREAD,
                                    search: `?${ROUTE_PARAMS.EPISODE_ID}=${episodeId}`,
                                    state: {
                                        patientName: getPatientName(),
                                    },
                                })
                            }
                        />
                    </div>
                    <Card>
                        <DataTable
                            value={conversation?.getConversations?.conversations}
                            dataKey="id"
                            selectionMode="single"
                            breakpoint="200px"
                            sortField="nextContactDate"
                            sortOrder={-1}
                            onRowClick={handleRowClick}
                            rowClassName={rowClassName}
                        >
                            <Column
                                field="lastActivityDate"
                                sortable
                                body={(s: Conversation) => dateTemplate(s.lastActivityDate)}
                                header="Last Activity"
                            />
                            <Column field="Topic" sortable body={(s: Conversation) => s.topic} header="Topic" />
                        </DataTable>
                    </Card>
                </>
            )}
        </div>
    );
};

export const ROUTES = {
    LOGIN: '/login',
    OAUTH_CALLBACK: '/oauth-callback',
    ERROR: '/error',
    LOGOUT: '/logout',
    APPT_SETTINGS: '/appt-settings',
    SETTINGS: '/settings',
    SCHEDULER: '/scheduler',
    MESSAGE_CENTER: '/message-center',
    MESSAGE_THREAD: '/message-thread',
};
export const ROUTE_PARAMS = {
    CONVERSATION_ID: 'conversationId',
    EPISODE_ID: 'episodeId',
};
